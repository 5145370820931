import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import SectionWrapper from '../../Common/SectionWrapper';

import waveIcon from '../../../assets/icons/wave-small-orange.svg';
import * as S from './Authors.Styles';

const Authors = () => {
  return (
    <SectionWrapper>
      <S.Wrapper>
        <S.WaveIcon src={waveIcon} alt="wave" />
        <S.Title>O autorkach</S.Title>
        <S.AuthorWrapper>
          <S.ImageWrapper>
            <StaticImage
              src="../../../assets/images/maria-kmita-book.png"
              alt="maria kmita"
              placeholder="blurred"
            />
          </S.ImageWrapper>
          <S.Description>
            <S.NameSpan>Maria Kmita </S.NameSpan>
            ma doktorat z humoru i nie zawaha się go użyć! W ramach Optymisji
            prowadzi podcast i warsztaty dla firm i instytucji. Na Uniwersytecie
            Medycznym we Wrocławiu wykłada Humor i pozytywną psychologię dla
            przyszłych lekarzy. Jest wieloletnim partnerem Wrocławskiego Centrum
            Rozwoju Społecznego. Wraz z Małgorzatą Jasionek prowadzi też
            Poprawczaka Nastroju na instagramie.
          </S.Description>
        </S.AuthorWrapper>
        <S.AuthorWrapper $placeRight>
          <S.ImageWrapper $placeRight>
            <StaticImage
              src="../../../assets/images/malgorzata-jasionek-book.png"
              alt="małgorzata jasionek"
              placeholder="blurred"
            />
          </S.ImageWrapper>
          <S.Description $placeLeft>
            <S.NameSpan>Małgorzata Jasionek </S.NameSpan>
            jest absolwentką Architektury na Politechnice Śląskiej. Z zawodu
            architektem, z pasji ilustratorką oraz grafikiem. Współzałożycielka
            wrocławskiego kolektywu „Zupa na Wolności” wspierającego osoby w
            kryzysie bezdomności. Za pomocą lekkiej kreski tworzy własny
            rysunkowy świat pełen humoru oraz oryginalnej interpretacji
            rzeczywistości. Wraz z Marią Kmitą prowadzi społecznie zaangażowaną,
            humorystyczną działalność na instagramie pod nazwą „Poprawczak
            Nastroju”.
          </S.Description>
        </S.AuthorWrapper>
      </S.Wrapper>
    </SectionWrapper>
  );
};

export default Authors;
