import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { query } from '../../../styles/mediaQuery';
import SectionWrapper from '../../Common/SectionWrapper';

import * as S from './BookDescription.Styles';
import waveIcon from '../../../assets/icons/wave-big-orange.svg';
import PrimaryButton from '../../Common/Buttons/PrimaryButton';

const BookDescription = () => {
  const isDesktop = useMediaQuery(query.desktop);

  return (
    <SectionWrapper>
      <S.Wrapper>
        <S.WaveImg src={waveIcon} alt="wave icon" />
        <S.Title>Poprawczak nastroju</S.Title>
        <S.Authors>
          <S.Author>
            Tekst:
            <S.BoldText> Maria Kmita</S.BoldText>
          </S.Author>
          <S.Author>
            Ilustracje:
            <S.BoldText> Małgorzata Jasionek</S.BoldText>
          </S.Author>
        </S.Authors>
        <S.Description>
          Poprawczak nastroju to zbiór zabawnych obserwacji i refleksji nad
          różnymi problemami życia codziennego osobników dorosłych. Książkę
          można czytać od początku, od końca lub od środka. Każdy tekst jest
          niezależny i opatrzony oryginalną i komiczną grafiką Małgorzaty
          Jasionek, z którą prowadzę Poprawczaka Nastroju na Instagramie.
        </S.Description>
        <S.ButtonsWrapper>
          <a
            href="https://allegro.pl/oferta/ksiazka-poprawczak-nastroju-14182669213?fbclid=IwAR3_hjf1v-_cCm_tgoWPvpD517q9CspuFtRVQbIl26qSNgEsXmqV-nIXDv8"
            target="_blank"
            rel="noopener noreferrer"
          >
            <PrimaryButton>Zamów książkę</PrimaryButton>
          </a>

          <a
            href="https://lubimyczytac.pl/ksiazka/5080176/poprawczak-nastroju"
            target="_blank"
            rel="noopener noreferrer"
          >
            <PrimaryButton outlined>Opinie</PrimaryButton>
          </a>
        </S.ButtonsWrapper>

        <S.PatronageWrapper>
          <StaticImage
            src="../../../assets/images/radio-luz-logo.png"
            alt="radio luz"
            placeholder="blurred"
            width={320}
          />
          <S.Patronage>Patronat</S.Patronage>
          {isDesktop && (
            <>
              <S.ImageWrapper $top="-10rem" $right="-40rem">
                <StaticImage
                  src="../../../assets/images/book-smiles/book-smile-1.png"
                  alt="smile 1"
                  placeholder="blurred"
                />
              </S.ImageWrapper>
              <S.ImageWrapper $bottom="0" $left="-40rem">
                <StaticImage
                  src="../../../assets/images/book-smiles/book-smile-5.png"
                  alt="smile 5"
                  placeholder="blurred"
                />
              </S.ImageWrapper>
            </>
          )}
        </S.PatronageWrapper>
        <S.WhereToBuy>
          <S.WhereToBuyHeader>
            Książki są do kupienia online (przycisk wyżej) oraz w najlepszych
            wrocławskich kawiarniach:
          </S.WhereToBuyHeader>
          <S.WhereToBuyList>
            <li>
              <S.Strong>Giaga</S.Strong>, ul. Edwarda Dembowskiego 18
            </li>
            <li>
              <S.Strong>Carolin & Simon</S.Strong>, ul. Partyzantów 16
            </li>
            <li>
              <S.Strong>Cafe De France</S.Strong>, Rynek Ratusz 20/22 1B
            </li>
            <li>
              <S.Strong>Kawiarnia Alfa</S.Strong>, ul. Nowowiejska 63
            </li>
            <li>
              <S.Strong>Don Dessert</S.Strong>, Benedykta Polaka 12/1b
            </li>
          </S.WhereToBuyList>
        </S.WhereToBuy>
      </S.Wrapper>
    </SectionWrapper>
  );
};

export default BookDescription;
