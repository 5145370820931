import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import SectionWrapper from '../../Common/SectionWrapper';

import waveIcon from '../../../assets/icons/wave-small-orange.svg';
import * as S from './BookHistory.Styles';
import { theme } from '../../../styles/theme';

const BookHistory = () => {
  return (
    <SectionWrapper backgroundColor={theme.color.backgroundSecondary}>
      <S.Wrapper>
        <S.WaveIcon src={waveIcon} alt="wave-icon" />
        <S.Title>Zobaczcie jak powstawała książka</S.Title>
        <S.Images>
          <S.ImageTitle1>I. Pisanie</S.ImageTitle1>
          <S.ImageBox>
            <StaticImage
              src="../../../assets/images/book-process-maria.png"
              alt="maria kmita"
              placeholder="blurred"
            />
          </S.ImageBox>
          <S.ImageDescription1>
            Natchniona i stylowa Maria K.
          </S.ImageDescription1>
          <S.ImageTitle2>II. Ilustrowanie</S.ImageTitle2>
          <S.ImageBox>
            <StaticImage
              src="../../../assets/images/book-process-malgorzata.png"
              alt="malgorzata jasionek"
              placeholder="blurred"
            />
          </S.ImageBox>
          <S.ImageDescription2>
            Skupiona i olśniewająca Małgorzata J.
          </S.ImageDescription2>
          <S.ImageTitle3>III. Poszukiwania najlepszego papieru</S.ImageTitle3>
          <S.ImageBox>
            <StaticImage
              src="../../../assets/images/book-process-both.png"
              alt="authors"
              placeholder="blurred"
            />
          </S.ImageBox>
          <S.ImageDescription3>
            Przejęte i intrygujące tudzież zaintrygowane Maria K. i Małgorzata
            J.
          </S.ImageDescription3>
        </S.Images>
      </S.Wrapper>
    </SectionWrapper>
  );
};

export default BookHistory;
