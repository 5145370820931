import React from 'react';
import { PrimaryButton as StyledPrimaryButton } from './Buttons.styles';

interface PrimaryButtonProps {
  onClick?: () => void;
  children: React.ReactNode;
  outlined?: boolean;
}

const PrimaryButton = ({ onClick, children, outlined }: PrimaryButtonProps) => {
  return (
    <StyledPrimaryButton onClick={onClick} $isOutlined={outlined}>
      {children}
    </StyledPrimaryButton>
  );
};

export default PrimaryButton;
