/* eslint-disable indent */
import styled, { css } from 'styled-components';
import { query } from '../../../styles/mediaQuery';

const sharedItemWidthStyles = css`
  max-width: 40rem;

  @media ${query.desktop} {
    max-width: 60rem;
  }
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const Title = styled.h2`
  color: ${({ theme }) => theme.color.textPrimary};
  font-family: ${({ theme }) => theme.font.family.archivo};
  font-size: ${({ theme }) => theme.font.size.headingSmall};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin: 0;
  position: relative;

  @media ${query.desktop} {
    font-size: ${({ theme }) => theme.font.size.heading};
    margin: 0;
    position: relative;
  }
`;

export const WaveIcon = styled.img`
  display: none;

  @media ${query.desktop} {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
  }
`;

export const Images = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 6.4rem;

  @media ${query.desktop} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 1.6rem;
  }
`;

export const ImageBox = styled.div`
  ${sharedItemWidthStyles}
`;

export const ImageTitle = styled.h3`
  ${sharedItemWidthStyles}

  color: ${({ theme }) => theme.color.textPrimary};
  font-family: ${({ theme }) => theme.font.family.archivo};
  font-size: ${({ theme }) => theme.font.size.paragraphBig};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin: 0 auto 1rem;
  text-align: center;

  &:not(:first-child) {
    margin-top: 3.2rem;

    @media ${query.desktop} {
      margin-top: 0;
    }
  }

  @media ${query.desktop} {
    font-size: ${({ theme }) => theme.font.size.paragraphBig};
    grid-row: 1/2;
    align-self: flex-start;
  }
`;

export const ImageDescription = styled.p`
  ${sharedItemWidthStyles}

  color: ${({ theme }) => theme.color.textPrimary};
  font-family: ${({ theme }) => theme.font.family.workSans};
  font-size: ${({ theme }) => theme.font.size.paragraph};
  font-weight: ${({ theme }) => theme.font.weight.regular};
  margin: 1rem auto 0;
  text-align: center;

  @media ${query.desktop} {
    grid-row: 3/4;
    align-self: flex-start;
  }
`;

export const ImageTitle1 = styled(ImageTitle)``;
export const ImageTitle2 = styled(ImageTitle)``;
export const ImageTitle3 = styled(ImageTitle)``;

export const ImageDescription1 = styled(ImageDescription)``;
export const ImageDescription2 = styled(ImageDescription)``;
export const ImageDescription3 = styled(ImageDescription)``;
