import React from 'react';
import Authors from './Authors';
import BookBanner from './BookBanner';
import BookDescription from './BookDescription';
import BookHistory from './BookHistory';
import Poster from './Poster';

const PoprawczakView = () => {
  return (
    <>
      <BookBanner />
      <BookDescription />
      <Poster />
      <BookHistory />
      <Authors />
    </>
  );
};

export default PoprawczakView;
