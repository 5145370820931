/* eslint-disable indent */
import styled from 'styled-components';
import { query } from '../../../styles/mediaQuery';
import { theme } from '../../../styles/theme';

export const Wrapper = styled.div`
  position: relative;
`;

export const Title = styled.h2`
  font-size: ${theme.font.size.headingSmall};
  font-family: ${theme.font.family.archivo};
  font-weight: ${theme.font.weight.bold};
  color: ${theme.color.textPrimary};
  margin: 0;

  @media ${query.desktop} {
    font-size: ${theme.font.size.heading};
  }
`;

export const AuthorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.2rem;
  margin-top: 6.4rem;

  @media ${query.desktop} {
    display: grid;
    grid-template-columns: minmax(30rem, 50rem) auto;
    grid-template-columns: ${({ $placeRight }) =>
      $placeRight ? 'auto minmax(30rem, 50rem)' : 'minmax(30rem, 50rem) auto'};
  }
`;

export const Description = styled.p`
  font-size: ${theme.font.size.button};
  font-family: ${theme.font.family.archivo};
  font-weight: ${theme.font.weight.regular};
  color: ${theme.color.textPrimary};
  margin: 0;
  text-align: center;

  @media ${query.desktop} {
    text-align: left;
    grid-row: 1/2;
    grid-column: ${({ $placeLeft }) => ($placeLeft ? '1/2' : '2/3')};
  }
`;

export const NameSpan = styled.span`
  font-weight: ${theme.font.weight.bold};
`;

export const ImageWrapper = styled.div`
  max-width: 50rem;

  @media ${query.desktop} {
    grid-column: ${({ $placeRight }) => ($placeRight ? '2/3' : '1/2')};
  }
`;

export const WaveIcon = styled.img`
  display: none;

  @media ${query.desktop} {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
  }
`;
