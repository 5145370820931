import styled, { css } from 'styled-components';
import { query } from '../../../styles/mediaQuery';
import { theme } from '../../../styles/theme';

const sharedText = css`
  text-align: center;
  font-size: ${theme.font.size.paragraph};
  font-family: ${theme.font.family.workSans};
  font-weight: ${theme.font.weight.regular};
  color: ${theme.color.textPrimary};
  margin: 0;
  max-width: 70rem;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  position: relative;

  @media ${query.desktop} {
    gap: 4rem;
  }
`;

export const Title = styled.h1`
  font-size: ${theme.font.size.heading};
  font-family: ${theme.font.family.archivo};
  font-weight: ${theme.font.weight.bold};
  color: ${theme.color.primaryOrange};
  text-align: center;
  margin: 0;

  @media ${query.desktop} {
    font-size: ${theme.font.size.headingBig};
    margin: 0;
  }
`;

export const Description = styled.p`
  ${sharedText}
`;

export const Authors = styled.div``;

export const Author = styled.p`
  text-align: center;
  font-size: ${theme.font.size.paragraph};
  font-family: ${theme.font.family.workSans};
  font-weight: ${theme.font.weight.regular};
  color: ${theme.color.textPrimary};
  margin: 0;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  @media ${query.desktop} {
    font-size: ${theme.font.size.paragraphBig};
  }
`;

export const BoldText = styled.span`
  font-weight: 700;
`;

export const PatronageWrapper = styled.div`
  margin-top: 1rem;
  position: relative;
`;

export const Patronage = styled.p`
  text-align: center;
  font-size: ${theme.font.size.paragraph};
  font-family: ${theme.font.family.workSans};
  font-weight: ${theme.font.weight.regular};
  color: ${theme.color.textSecondary};
  margin: 0;
`;

export const WhereToBuy = styled.div``;

export const WhereToBuyHeader = styled.h3`
  ${sharedText}
`;

export const WhereToBuyList = styled.ul`
  ${sharedText}

  li {
    margin: 0.8rem auto;

    &::before {
      content: '-';
      display: inline;
      margin-right: 0.8rem;
    }
  }
`;

export const Strong = styled.strong`
  font-weight: 700;
`;

export const ImageWrapper = styled.div`
  position: absolute;
  max-width: 250px;

  top: ${({ $top }) => $top ?? 'auto'};
  right: ${({ $right }) => $right ?? 'auto'};
  left: ${({ $left }) => $left ?? 'auto'};
  bottom: ${({ $bottom }) => $bottom ?? 'auto'};
`;

export const WaveImg = styled.img`
  display: none;

  @media ${query.desktop} {
    display: block;
    position: absolute;
    left: -100rem;
    top: 10rem;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;

  & > * {
    width: 100%;
  }

  button {
    width: 100%;
  }
`;
