import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';
import { query } from '../../../styles/mediaQuery';

export const Wrapper = styled.div`
  height: 19rem;
  position: relative;

  @media ${query.desktop} {
    height: 40rem;
  }

  div {
    height: 100% !important;
    width: 100% !important;
  }

  img {
    position: absolute;
    top: -20% !important;
    height: 120% !important;

    @media ${query.desktop} {
      height: 140% !important;

      top: -50% !important;
      height: 150% !important;
    }
  }
`;

const BookBanner = () => {
  return (
    <Wrapper>
      <StaticImage src="../../../assets/images/book.png" alt="book" />
    </Wrapper>
  );
};

export default BookBanner;
