import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';
import { query } from '../../../styles/mediaQuery';

const ImageWrapper = styled.div`
  max-width: 60rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  @media ${query.desktop} {
    max-width: 80rem;
  }
`;

const Poster = () => {
  return (
    <ImageWrapper>
      <StaticImage
        src="../../../assets/images/poprawczak-plakat.png"
        alt="plakat"
        placeholder="blurred"
      />
    </ImageWrapper>
  );
};

export default Poster;
